import React, { Component } from 'react';
import { Link } from "gatsby"
import Img from "gatsby-image"
import PubSub from 'pubsub-js';

class HomeCard extends Component {
    constructor(props) {
        super(props);
        this._onImgLoad = this._onImgLoad.bind(this)
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token)
    }
    componentDidMount(){
        
        // this.token = PubSub.subscribe("LOADED", () => {    
        //     const { height } = this.refs.images.querySelector(".gatsby-image-wrapper").getBoundingClientRect()        

        //     // console.log(this.refs.images)
        //     // console.log("height", height)

        //     this.refs.images.style.height = height+"px"
        // })
    }

    _onImgLoad(){
        // const { height } = this.refs.images.getBoundingClientRect()
        // console.log(this.refs.images)
        // console.log("height", height)
    }

    render() {
        const { data } = this.props
        // console.log(data)
        // const delay = index/10
        // const style = {
        //     transitionDuration: '0.8s',
        //     transitionDelay: delay+"s"
        //     //position: 'initial'
        // }
        return (
            <div className="project " ref="card" >
                <div className="header list-item  crtch">
                    <Link to={'realisation/'+data.slug}>
                        <h2>{data.titre}</h2>
                    </Link>
                </div>
                <div className="images" ref="images">
                    <Link to={'realisation/'+data.slug}>
                        <div className="row">
                        {data.images_couverture.map((image,i) => (
                            <div className={"col-xs "+(i>0 ? "hidden-xs" : "")} key={i}>
                                <Img 
                                fluid={image.fluid} 
                                onLoad={this._onImgLoad}
                                alt={image.description}
                                />
                            </div>
                        ))}
                        </div>
                    </Link>
                </div>
                
            </div>
        );
    }
}

export default HomeCard;