import React from 'react';
import {graphql} from 'gatsby';
import PubSub from 'pubsub-js';
//import cookie from 'react-cookies'
import SEO from '../components/SEO'
import HomeCard from '../components/HomeCard';

class PageHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount(){
    setTimeout(() => {
      // document.body.classList.remove("loading")
      // document.body.classList.add("loaded")
      PubSub.publish("LOADED")
    }, 400)
  }

  render() {
    //console.log(this.props)
    const {
      // options,
      home:{
        seo_partage,
        realisations
      }
    } = this.props.data
    
    return (
      <>
        <div className="home">
          <SEO
            page_title={seo_partage.page_title}
            meta_description={seo_partage.meta_description.childMarkdownRemark.rawMarkdownBody}
            poster={seo_partage.poster_image.file.url}
            bodyClass="template-home "
            // pathname={location.pathname} 
            />
       
          <section className="projects">
            {realisations.map((item,i) => (
              <HomeCard data={item} index={i} key={i} />
            ))}
            
          </section>
        </div>
        
        
      </>
    )
  }
}

export default PageHome;

export const pageQuery = graphql `
  query homePage{
    # options: contentfulOptions{
    #   ...options
    # }
    home: contentfulAccueil{
      ...home
    }
  }
`   